<template>
  <hierarchical-index
    :dbref="dbref"
    :title="title"
    :new-button-label="newButtonLabel"
    :fields="fields"
    :search-fields="searchFields"
  >
    <template #cell(type)="data">
      <span v-if="!data.item.isFolder" :class="data.item.type">
        {{ data.item.type === "graphql" ? "GraphQL" : "REST" }}
      </span>
    </template>
    <template #cell(method)="data">
      <b-badge
        v-if="!data.item.isFolder"
        :variant="methodVariant(data.item.method)"
      >
        {{ String(data.item.method).toUpperCase() }}
      </b-badge>
    </template>
    <template #cell(endpoint)="data">
      <span v-if="!data.item.isFolder">&#47;{{ data.item.endpoint }}</span>
    </template>
  </hierarchical-index>
</template>

<script>
import HierarchicalIndex from "@/components/HierarchicalIndex";

export default {
  name: "APIs",
  inject: ["notyf"],
  components: {
    HierarchicalIndex,
  },
  data() {
    return {
      dbref: "apis",
      title: "APIs",
      newButtonLabel: "New API",
      fields: [
        {
          key: "select",
          label: "",
          sortable: false,
        },
        {
          key: "icon",
          label: "",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "method",
          label: "Method",
          sortable: true,
        },
        {
          key: "endpoint",
          label: "Endpoint",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      searchFields: ["name", "description", "type", "method", "endpoint"],
    };
  },
  methods: {
    methodVariant(method) {
      switch (method) {
        case "get":
          return "success";
        case "post":
          return "warning";
        case "put":
          return "primary";
        case "delete":
          return "danger";
        default:
          return "secondary";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.graphql {
  color: #d64292;
}
.rest {
  color: #3f80ea;
}
</style>
