<template>
  <form-wizard
    id="wizard"
    class="wizard wizard-primary sw sw-theme-default sw-justified"
    color="#3f80ea"
    @on-complete="save(true)"
    @on-save="save(false)"
    :hideSaveButton="isNew"
    :finishButtonText="isNew ? 'Create' : 'Save & Exit'"
  >
    <template v-slot:custom-buttons-right>
      <commit-to-github
        v-if="project.github_status === 'connected' && !isNew"
        :project="{ key: projectId, ...project }"
        :path="api.github_path"
        :processing="githubProcessing"
        class="mr-1"
        button-class="commit-button"
        @commit="commitScript"
      />
    </template>
    <tab-content title="Details" description="Specify configuration details">
      <form>
        <b-form-group label="Name">
          <b-form-input
            type="text"
            placeholder="e.g. My New API"
            v-model="api.name"
            @input="updateEndpoint"
            required
            trim
          />
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            v-model="api.description"
            placeholder="e.g. This is a description of my new API..."
            rows="4"
            max-rows="4"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Type">
          <b-form-radio-group
            v-model="api.type"
            :options="[
              { value: 'rest', text: 'REST' },
              { value: 'graphql', text: 'GraphQL' },
            ]"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group label="Method">
          <b-form-radio-group
            v-model="api.method"
            :options="[
              { value: 'get', text: 'GET' },
              { value: 'post', text: 'POST' },
              { value: 'put', text: 'PUT' },
              { value: 'patch', text: 'PATCH' },
              { value: 'delete', text: 'DELETE' },
              { value: 'options', text: 'OPTIONS' },
              { value: 'other', text: 'Other' },
            ]"
          ></b-form-radio-group>
          <b-form-input
            type="text"
            class="mt-2"
            placeholder="e.g. CUSTOM"
            v-model="api.method_other"
            v-if="api.method === 'other'"
            trim
          />
        </b-form-group>
        <b-form-group label="Endpoint">
          <b-input-group :prepend="apiUrl">
            <b-form-input
              type="text"
              placeholder="e.g. my-new-api"
              v-model="api.endpoint"
              required
              trim
            />
            <b-input-group-append>
              <b-button @click="copyUrl" v-b-tooltip.click.right="'Copied!'">
                <i class="far fa-copy"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </form>
    </tab-content>
    <tab-content title="Processing" description="Customize endpoint processing">
      <b-form inline class="text-center justify-content-center">
        <b-form-group label="Approach" class="mb-3">
          <b-form-radio-group
            v-model="api.approach"
            :options="[
              { value: 'guided', text: 'Guided' },
              { value: 'script', text: 'Script' },
            ]"
          ></b-form-radio-group>
        </b-form-group>
      </b-form>
      <div v-if="api.approach === 'guided'">
        <b-container fluid>
          <b-row>
            <b-col>
              <b-card class="field-card">
                <b-card-title>
                  Steps
                  <div class="mt-1 mb-2 d-flex justify-content-between">
                    <small class="text-muted">
                      Click the Add Step button to add processing steps to the
                      flow.<br />
                      Steps can be dragged-and-dropped to change the order.
                    </small>
                    <div>
                      <b-dropdown variant="primary" size="sm" right>
                        <template #button-content>
                          <i class="fas fa-plus"></i> Add Step
                        </template>
                        <b-dropdown-item
                          v-for="(step, index) in relevantSteps"
                          :key="index"
                          @click="addStep(step)"
                        >
                          <h6 class="my-1">{{ step.name }}</h6>
                          <small class="text-muted">
                            {{ step.description }}
                          </small>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </b-card-title>
                <draggable
                  tag="b-list-group"
                  :list="api.steps"
                  class="step-list mb-3 p-1"
                  handle=".list-handle"
                >
                  <b-list-group-item
                    v-for="(step, index) in api.steps"
                    :key="index"
                    :class="[
                      'px-1',
                      'cursor-pointer',
                      index === stepIndex ? 'bg-primary' : '',
                    ]"
                    @click="editStep(step, index)"
                  >
                    <div class="list-handle">
                      <i class="fas fa-grip-vertical p-2"></i>
                    </div>
                    <div class="float-left">
                      <h5 class="my-1">{{ step.type.name }}</h5>
                      <small
                        class="text-danger"
                        v-if="
                          step.type.name === 'Authenticate' &&
                          (!('credentials' in step) ||
                            step.credentials === null)
                        "
                      >
                        <i class="fas fa-exclamation-circle"></i> Needs
                        configuration
                      </small>
                      <small
                        class="text-muted"
                        v-if="
                          step.type.name === 'Authenticate' &&
                          'credentials' in step &&
                          step.credentials !== null
                        "
                      >
                        Use credentials <i>{{ step.credentials.name }}</i
                        >. If authentication fails, return status code
                        {{ step.status }}.
                      </small>
                      <small
                        class="text-danger"
                        v-if="
                          step.type.name === 'Schema' &&
                          (!('schema' in step) || step.schema === null)
                        "
                      >
                        <i class="fas fa-exclamation-circle"></i> Needs
                        configuration
                      </small>
                      <small
                        class="text-muted"
                        v-if="
                          step.type.name === 'Schema' &&
                          'schema' in step &&
                          step.schema !== null
                        "
                      >
                        Use schema <i>{{ step.schema.name }}</i
                        >. Export {{ step.count }} records.
                      </small>
                      <small
                        class="text-danger"
                        v-if="
                          step.type.name === 'Store Data' &&
                          (!('data' in step) || step.data === null)
                        "
                      >
                        <i class="fas fa-exclamation-circle"></i> Needs
                        configuration
                      </small>
                      <small
                        class="text-muted"
                        v-if="
                          step.type.name === 'Store Data' &&
                          'data' in step &&
                          step.data !== null
                        "
                      >
                        Store received data in <i>{{ step.data.name }}</i
                        >.
                      </small>
                      <small
                        class="text-danger"
                        v-if="
                          step.type.name === 'Filter' &&
                          step.conditions.length === 0
                        "
                      >
                        <i class="fas fa-exclamation-circle"></i> Needs
                        configuration
                      </small>
                      <small
                        class="text-muted"
                        v-if="
                          step.type.name === 'Filter' &&
                          step.conditions.length > 0
                        "
                      >
                        Filter data using
                        {{ step.conditions.length }} condition(s).
                      </small>
                      <small
                        class="text-muted"
                        v-if="step.type.name === 'Response'"
                      >
                        Respond with status code
                        {{ step.status }}.
                      </small>
                      <small
                        class="text-danger"
                        v-if="
                          (step.type.name === 'GraphQL Query' ||
                            step.type.name === 'GraphQL Mutation') &&
                          'schemas' in step &&
                          Object.keys(step.schemas).length === 0
                        "
                      >
                        <i class="fas fa-exclamation-circle"></i> Needs
                        configuration
                      </small>
                      <small
                        class="text-muted"
                        v-if="
                          step.type.name === 'GraphQL Query' &&
                          'schemas' in step &&
                          Object.keys(step.schemas).length > 0
                        "
                      >
                        Query using
                        {{ Object.keys(step.schemas).length }} schema{{
                          Object.keys(step.schemas).length > 1 ? "s" : ""
                        }}.
                      </small>
                      <small
                        class="text-muted"
                        v-if="
                          step.type.name === 'GraphQL Mutation' &&
                          'schemas' in step &&
                          Object.keys(step.schemas).length > 0
                        "
                      >
                        Mutate using
                        {{ Object.keys(step.schemas).length }} schema{{
                          Object.keys(step.schemas).length > 1 ? "s" : ""
                        }}.
                      </small>
                    </div>
                    <div class="float-right">
                      <b-button
                        variant="danger"
                        size="sm"
                        class="mr-1"
                        @click.stop="deleteStep(index)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </b-button>
                    </div>
                  </b-list-group-item>
                </draggable>
              </b-card>
            </b-col>
            <b-col>
              <b-card class="field-card">
                <b-card-title v-if="stepIndex !== null">
                  Configure Step
                  <span class="float-right font-italic">
                    {{ step.type.name }}
                    <span>
                      <b-button
                        variant="success"
                        size="sm"
                        title="Save Changes"
                        v-b-tooltip.hover.bottom
                        class="ml-2 mr-1"
                        @click="saveStep"
                        ><i class="far fa-save"></i
                      ></b-button>
                      <b-button
                        variant="warning"
                        size="sm"
                        title="Revert Changes"
                        v-b-tooltip.hover.bottom
                        @click="revertStep"
                        ><i class="fas fa-ban"></i
                      ></b-button>
                    </span>
                  </span>
                </b-card-title>
                <b-card-text v-if="stepIndex === null">
                  <b-alert variant="info" show
                    ><div class="alert-message">
                      <i class="fas fa-exclamation-circle"></i> Please select a
                      step.
                    </div></b-alert
                  >
                </b-card-text>
                <b-card-text
                  v-if="stepIndex !== null && step.type.name === 'Authenticate'"
                >
                  <b-row>
                    <b-col>
                      <b-form-group label="Credentials">
                        <b-form-select
                          :options="credentialList"
                          text-field="name"
                          value-field="value"
                          v-model="step.credentials"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span>If authentication fails, return:</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Status Code">
                        <b-form-input
                          type="number"
                          v-model="step.status"
                          placeholder="e.g. 401"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text
                  v-if="stepIndex !== null && step.type.name === 'Schema'"
                >
                  <b-row>
                    <b-col>
                      <b-form-group label="Schema">
                        <b-form-select
                          :options="schemaList"
                          text-field="name"
                          value-field="value"
                          v-model="step.schema"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Number of Records">
                        <b-form-input
                          type="number"
                          v-model="step.count"
                          placeholder="e.g. 10"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text
                  v-if="stepIndex !== null && step.type.name === 'Store Data'"
                >
                  <b-row>
                    <b-col>
                      <b-form-group label="Data Store">
                        <b-form-select
                          :options="dataList"
                          text-field="name"
                          value-field="value"
                          v-model="step.data"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Field Mapping">
                        <b-table :items="step.mappings" :fields="mappingFields">
                          <template #cell(field)="data">
                            <b-form-select
                              v-model="data.item.field"
                              :options="dataFields(step.data.key)"
                              text-field="name"
                              value-field="name"
                            ></b-form-select>
                          </template>
                          <template #cell(type)="data">
                            <b-form-select
                              v-model="data.item.type"
                              :options="[
                                { value: 'field', text: 'Body Field' },
                                { value: 'static', text: 'Static Value' },
                                { value: 'variable', text: 'Variable' },
                              ]"
                            ></b-form-select>
                          </template>
                          <template #cell(source)="data">
                            <b-form-input
                              v-model="data.item.source"
                              placeholder="e.g. Field 1"
                            ></b-form-input>
                          </template>
                          <template #cell(actions)="data">
                            <b-button
                              variant="danger"
                              size="sm"
                              @click="deleteMapping(data.index)"
                              ><i class="far fa-trash-alt"></i
                            ></b-button>
                          </template>
                        </b-table>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="float-right mt-1"
                          @click="addMapping"
                          :disabled="!step.data"
                        >
                          <i class="fas fa-plus"></i> Add
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text
                  v-if="stepIndex !== null && step.type.name === 'Filter'"
                >
                  <b-row>
                    <b-col>
                      <b-alert
                        variant="warning"
                        v-if="schemaSteps.length === 0"
                        show
                      >
                        <div class="alert-message">
                          No fields will be available for filter conditions
                          without a Schema step.
                        </div>
                      </b-alert>
                      <b-form-group label="Conditions">
                        <b-table
                          :items="step.conditions"
                          :fields="conditionFields"
                        >
                          <template #cell(field)="data">
                            <b-form-select
                              v-model="data.item.field"
                              :options="schemaStepFields"
                              text-field="name"
                            ></b-form-select>
                          </template>
                          <template #cell(operator)="data">
                            <b-form-select
                              v-model="data.item.operator"
                              :options="['==', '!=', '>', '>=', '<', '<=']"
                            ></b-form-select>
                          </template>
                          <template #cell(value)="data">
                            <b-form-input
                              v-model="data.item.value"
                              placeholder="e.g. Field 1"
                            ></b-form-input>
                          </template>
                          <template #cell(actions)="data">
                            <b-button
                              variant="danger"
                              size="sm"
                              @click="deleteCondition(data.index)"
                              ><i class="far fa-trash-alt"></i
                            ></b-button>
                          </template>
                        </b-table>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="float-right mt-1"
                          @click="addCondition"
                        >
                          <i class="fas fa-plus"></i> Add
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text
                  v-if="stepIndex !== null && step.type.name === 'Response'"
                >
                  <b-row>
                    <b-col>
                      <b-form-group label="Status Code">
                        <b-form-input
                          type="number"
                          v-model="step.status"
                          placeholder="e.g. 200"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Body Format">
                        <b-form-select
                          :options="[
                            { value: 'none', text: 'None' },
                            { value: 'json', text: 'JSON' },
                            { value: 'text', text: 'Text' },
                          ]"
                          v-model="step.format"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="step.format === 'json'">
                    <b-col>
                      <b-form-group label="Body Layout">
                        <small class="text-muted">
                          Use [[items]] to insert outputted data array. Use
                          [[count]] for total count of data array;
                        </small>
                        <prism-editor
                          v-model="step.layout"
                          language="json"
                          line-numbers
                          :highlight="highlighter"
                          class="prism-editor prism-editor-sm mt-1"
                        ></prism-editor>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="step.format === 'text'">
                    <b-col>
                      <b-form-group label="Delimiter">
                        <b-form-input
                          type="text"
                          placeholder="e.g. ,"
                          v-model="step.delimiter"
                          required
                          trim
                        />
                      </b-form-group>
                      <b-form-group label="Qualifier">
                        <b-form-input
                          type="text"
                          placeholder='e.g. "'
                          v-model="step.qualifier"
                          trim
                        />
                      </b-form-group>
                      <b-form-group label="Use Qualifier">
                        <b-form-radio-group
                          v-model="step.use_qualifier"
                          :options="[
                            { value: 'always', text: 'Always' },
                            { value: 'needed', text: 'When Needed' },
                            { value: 'never', text: 'Never' },
                          ]"
                        ></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text
                  v-if="
                    stepIndex !== null && step.type.name === 'GraphQL Query'
                  "
                >
                  <b-alert
                    variant="warning"
                    show
                    v-if="
                      !('schemas' in step) ||
                      Object.keys(step.schemas).length === 0
                    "
                    ><div class="alert-message">
                      <i class="fas fa-exclamation-circle"></i> Please use
                      Schema step(s) to specify schemas
                    </div></b-alert
                  >
                  <p
                    v-if="
                      'schemas' in step && Object.keys(step.schemas).length > 0
                    "
                  >
                    Select fields that can be queried:
                  </p>
                  <b-card
                    v-for="schema in Object.values(step.schemas)"
                    :key="schema.key"
                    no-body
                  >
                    <b-card-title
                      >Schema: {{ schema.name }}
                      <small class="float-right">
                        <b-form-checkbox
                          switch
                          @change="toggleSelected($event, schema.key)"
                        ></b-form-checkbox>
                      </small>
                    </b-card-title>
                    <b-card-text>
                      <b-form>
                        <b-form-group label="GraphQL Type Name">
                          <b-form-input
                            v-model="step.schemas[schema.key].type_name"
                          />
                        </b-form-group>
                        <b-form-group label="GraphQL Plural Name">
                          <b-form-input
                            v-model="step.schemas[schema.key].plural_name"
                          />
                        </b-form-group>
                        <b-form-group label="GraphQL ID Field Name">
                          <b-form-input
                            v-model="step.schemas[schema.key].index_name"
                          />
                        </b-form-group>
                      </b-form>
                    </b-card-text>
                    <b-form-checkbox-group
                      v-model="step.schemas[schema.key].fields"
                      switches
                    >
                      <b-list-group>
                        <b-list-group-item
                          class="d-flex justify-content-between align-items-center"
                          v-for="(field, index) in schemas[schema.key].fields"
                          :key="index"
                        >
                          {{ field.name }}
                          <b-form-checkbox
                            :value="field.name"
                          ></b-form-checkbox>
                        </b-list-group-item>
                      </b-list-group>
                    </b-form-checkbox-group>
                  </b-card>
                </b-card-text>
                <b-card-text
                  v-if="
                    stepIndex !== null && step.type.name === 'GraphQL Mutation'
                  "
                >
                  <b-alert
                    variant="warning"
                    show
                    v-if="
                      !('schemas' in step) ||
                      Object.keys(step.schemas).length === 0
                    "
                    ><div class="alert-message">
                      <i class="fas fa-exclamation-circle"></i> Please use
                      Schema step(s) to specify schemas
                    </div></b-alert
                  >
                  <p
                    v-if="
                      'schemas' in step && Object.keys(step.schemas).length > 0
                    "
                  >
                    Select fields that can be mutated:
                  </p>
                  <b-card
                    v-for="schema in Object.values(step.schemas)"
                    :key="schema.key"
                    no-body
                  >
                    <b-card-title
                      >Schema: {{ schema.name }}
                      <small class="float-right">
                        <b-form-checkbox
                          switch
                          @change="toggleSelected($event, schema.key)"
                        ></b-form-checkbox>
                      </small>
                    </b-card-title>
                    <b-card-text>
                      <b-form>
                        <b-form-group label="GraphQL Type Name">
                          <b-form-input
                            v-model="step.schemas[schema.key].type_name"
                          />
                        </b-form-group>
                        <b-form-group label="GraphQL Plural Name">
                          <b-form-input
                            v-model="step.schemas[schema.key].plural_name"
                          />
                        </b-form-group>
                        <b-form-group label="GraphQL ID Field Name">
                          <b-form-input
                            v-model="step.schemas[schema.key].index_name"
                          />
                        </b-form-group>
                      </b-form>
                    </b-card-text>
                    <b-form-checkbox-group
                      v-model="step.schemas[schema.key].fields"
                      switches
                    >
                      <b-list-group>
                        <b-list-group-item
                          class="d-flex justify-content-between align-items-center"
                          v-for="(field, index) in schemas[schema.key].fields"
                          :key="index"
                        >
                          {{ field.name }}
                          <b-form-checkbox
                            :value="field.name"
                          ></b-form-checkbox>
                        </b-list-group-item>
                      </b-list-group>
                    </b-form-checkbox-group>
                  </b-card>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div v-if="api.approach === 'script'">
        <b-alert :show="alert.show" :variant="alert.type">
          <div class="alert-message">
            <i class="far fa-check-circle" v-if="alert.type === 'success'"></i>
            <i
              class="fas fa-exclamation-circle"
              v-if="alert.type === 'danger'"
            ></i>
            {{ alert.message }}
          </div>
        </b-alert>
        <b-container fluid>
          <b-row>
            <b-col>
              <b-card class="field-card">
                <b-card-title>
                  Script
                  <div class="mt-1 mb-2 d-flex justify-content-between">
                    <small class="text-muted">
                      Manually script the API processing using JavaScript.<br />
                      Example scripts can be found
                      <b-link
                        href="https://github.com/API-Faker-Dev/example-scripts"
                        >here</b-link
                      >.
                    </small>
                    <div v-if="false">
                      <b-button
                        variant="warning"
                        size="sm"
                        @click="performValidation"
                        ><i class="far fa-check-circle"></i> Validate</b-button
                      >
                    </div>
                  </div>
                </b-card-title>
                <prism-editor
                  v-model="script"
                  language="js"
                  line-numbers
                  :highlight="highlighter"
                  class="prism-editor mt-1"
                ></prism-editor>
              </b-card>
            </b-col>
            <b-col>
              <b-card class="field-card">
                <b-card-title>Reference</b-card-title>
                <b-card-text
                  ><small class="text-muted"
                    >These system variables and functions are available for use
                    in scripts (in addition to most built-in JavaScript
                    objects).</small
                  ></b-card-text
                >
                <b-tabs pills>
                  <b-tab title="Variables" active>
                    <b-card-text>
                      <b-list-group flush>
                        <b-list-group-item
                          v-for="(variable, index) in metaScripting.variables"
                          :key="index"
                        >
                          <b-row>
                            <b-col cols="3">
                              <b> {{ variable.name }} </b><br />
                              <small class="text-muted mt-1 font-italic">
                                {{ variable.description }}
                              </small>
                            </b-col>
                            <b-col>
                              <b-list-group flush>
                                <b-list-group-item
                                  v-for="(
                                    property, jindex
                                  ) in variable.properties"
                                  :key="jindex"
                                  class="py-1"
                                >
                                  <b-button
                                    variant="link"
                                    class="list-method"
                                    @click="
                                      selectProperty(
                                        variable.name + '.' + property.name
                                      )
                                    "
                                  >
                                    {{ property.name }}</b-button
                                  >&nbsp;
                                  <i
                                    >({{ property.type }},
                                    {{ property.mutable }})</i
                                  >
                                  <br /><small>{{
                                    property.description
                                  }}</small>
                                </b-list-group-item>
                              </b-list-group>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Functions">
                    <b-card-text>
                      <b-list-group flush>
                        <b-list-group-item
                          v-for="(func, index) in metaScripting.functions"
                          :key="index"
                        >
                          <b-row>
                            <b-col cols="3">
                              <b> {{ func.name }} </b><br />
                              <small class="text-muted mt-1 font-italic">
                                {{ func.description }}
                              </small>
                            </b-col>
                            <b-col>
                              <b-list-group flush>
                                <b-list-group-item
                                  v-for="(property, jindex) in func.properties"
                                  :key="jindex"
                                  class="py-1"
                                >
                                  <b-button
                                    variant="link"
                                    class="list-method"
                                    @click="
                                      selectProperty(
                                        func.name + '.' + property.name + '()'
                                      )
                                    "
                                  >
                                    {{ property.name }}</b-button
                                  >&nbsp;
                                  <i>({{ property.return }})</i>
                                  <br /><small>{{
                                    property.description
                                  }}</small>
                                </b-list-group-item>
                              </b-list-group>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </tab-content>
  </form-wizard>
</template>

<script>
import { mapState } from "vuex";
import FormWizard from "@/components/CustomFormWizard";
import TabContent from "@/components/CustomTabContent";
import { db } from "@/firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import CommitToGithub from "@/components/CommitToGithub";
//import Scripts from "@/firebase/scripts";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import draggable from "vuedraggable";
import slugify from "slugify";
import camelCase from "camelcase";

export default {
  name: "APIEditor",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    FormWizard,
    TabContent,
    PrismEditor,
    draggable,
    CommitToGithub,
  },
  props: {
    api: {
      type: Object,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bind: [],
      project: {},
      endpoints: {},
      credentials: {},
      schemas: {},
      data: {},
      stepIndex: null,
      step: {
        mappings: [],
      },
      script: "",
      alert: {
        show: false,
        type: "success",
        message: "",
      },
      metaSteps: [],
      metaScripting: {
        variables: {},
        functions: {},
      },
      mappingFields: [
        {
          key: "field",
          label: "Field",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "source",
          label: "Source",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: true,
        },
      ],
      conditionFields: [
        {
          key: "field",
          label: "Field",
          sortable: true,
        },
        {
          key: "operator",
          label: "Operator",
          sortable: true,
        },
        {
          key: "value",
          label: "Value",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: true,
        },
      ],
      githubProcessing: false,
    };
  },
  firebase: {
    metaSteps: db.ref("meta/steps/api"),
    metaScripting: db.ref("meta/scripting/api"),
  },
  computed: {
    ...mapState(["config"]),
    apiUrl() {
      return `${this.config.api_url}${this.api.type}/${this.project.path}/`;
    },
    relevantSteps() {
      return this.metaSteps.filter((step) => {
        return step.type === "any" || step.type === this.api.type;
      });
    },
    credentialList() {
      return [
        {
          key: null,
          name: "Please select credentials",
          disabled: true,
        },
        ...Object.keys(this.credentials).map((cred, index) => {
          return {
            key: index,
            value: { key: cred, name: this.credentials[cred].name },
            name: this.credentials[cred].name,
          };
        }),
      ];
    },
    schemaList() {
      return [
        {
          key: null,
          name: "Please select a schema",
          disabled: true,
        },
        ...Object.keys(this.schemas).map((schema, index) => {
          return {
            key: index,
            value: { key: schema, name: this.schemas[schema].name },
            name: this.schemas[schema].name,
          };
        }),
      ];
    },
    dataList() {
      return [
        {
          key: null,
          name: "Please select a data store",
          disabled: true,
        },
        ...Object.keys(this.data).map((data, index) => {
          return {
            key: index,
            value: { key: data, name: this.data[data].name },
            name: this.data[data].name,
          };
        }),
      ];
    },
    schemaSteps() {
      return this.api.steps.filter((step) => {
        return step.type.name === "Schema";
      });
    },
    schemaStepFields() {
      let fields = [];
      this.schemaSteps.forEach((step) => {
        if (
          "schema" in step &&
          step.schema !== null &&
          Array.isArray(this.schemas[step.schema.key].fields)
        ) {
          this.schemas[step.schema.key].fields.forEach((field) => {
            if (!fields.includes(field.name)) {
              const name = step.schema.name + "." + field.name;
              fields.push(name);
            }
          });
        }
      });
      return fields;
    },
    isNew() {
      return !("path" in this.api);
    },
  },
  watch: {
    projectId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.bindObject("projects", id, "project");
          this.bindObject("credentials", id, "credentials");
          this.bindObject("schemas", id, "schemas");
          this.bindObject("data", id, "data");
        }
      },
    },
    api: {
      immediate: true,
      handler(api) {
        if (!("steps" in api) || !Array.isArray(api.steps)) {
          this.api.steps = [];
        } else {
          for (let step of this.api.steps) {
            step = this.initStep(step);
          }
        }
        if (!this.script || this.script.length === 0) {
          this.script = this.api.script;
        }
        if (!api.github_path) {
          const name = api.name.replace(/[/\\?%*:|"<> ]/g, "-");
          api.github_path = `Scripts/${name}-${Date.now()}.js`;
        }
      },
    },
  },
  methods: {
    async performValidation() {
      const result = await this.validate();
      // console.log("Result", result);
      if (result.valid) {
        this.alert.type = "success";
        this.alert.message = "Validation successful";
      } else {
        this.alert.type = "danger";
        this.alert.message = result.message;
      }
      this.$set(this.alert, "show", true);
    },
    async validate() {
      // const s = new Scripts();
      // const result = await s.validate(this.api.script);
      // console.log(result);
      //return result.data;
      return { valid: true };
    },
    async save(exit = false) {
      const result = await this.validate();
      if (result.valid) {
        this.api.script = this.script;
        if (exit) {
          this.$emit("save-and-exit", this.api);
        } else {
          this.$emit("save", this.api);
        }
      } else {
        this.notyf.error("Script contains error(s)");
      }
    },
    initStep(step) {
      // console.log("Init Before", JSON.stringify(step));
      // Handle defaults
      switch (step.type.name) {
        case "Authenticate":
          if (!("credentials" in step)) {
            step.credentials = null;
          }
          if (!("status" in step)) {
            step.status = 401;
          }
          break;
        case "Schema":
          if (!("schema" in step)) {
            step.schema = null;
          }
          break;
        case "Store Data":
          if (!("data" in step)) {
            step.data = null;
          }
          if (!("data" in step) || !Array.isArray(step.mappings)) {
            step.mappings = [];
          }
          break;
        case "Filter":
          if (!("conditions" in step) || !Array.isArray(step.conditions)) {
            step.conditions = [];
          }
          break;
        case "GraphQL Query": {
          if (!("schema" in step) || step.schema === null) {
            // Get schema from Schema step (if present)
            if (
              !step.schemas ||
              typeof step.schemas !== "object" ||
              step.schemas === null
            ) {
              step.schemas = {};
            }
            const schemas = this.api.steps
              .filter(
                (step) =>
                  step.type.name === "Schema" &&
                  "schema" in step &&
                  step.schema !== null
              )
              .map((step) => step.schema);
            if (schemas.length > 0) {
              for (const schema of schemas) {
                if (!(schema.key in step.schemas)) {
                  step.schemas[schema.key] = {
                    key: schema.key,
                    name: schema.name,
                    type_name: camelCase(schema.name),
                    plural_name: camelCase(schema.name) + "s",
                    index_name: "id",
                    fields: [],
                  };
                } else {
                  step.schemas[schema.key].fields = Array.isArray(
                    step.schemas[schema.key].fields
                  )
                    ? step.schemas[schema.key].fields
                    : [];
                  step.schemas[schema.key].type_name =
                    !step.schemas[schema.key].type_name ||
                    step.schemas[schema.key].type_name.length === 0
                      ? camelCase(schema.name)
                      : step.schemas[schema.key].type_name;
                  step.schemas[schema.key].plural_name =
                    !step.schemas[schema.key].plural_name ||
                    step.schemas[schema.key].plural_name.length === 0
                      ? camelCase(schema.name) + "s"
                      : step.schemas[schema.key].plural_name;
                  step.schemas[schema.key].index_name =
                    !step.schemas[schema.key].index_name ||
                    step.schemas[schema.key].index_name.length === 0
                      ? "id"
                      : step.schemas[schema.key].index_name;
                }
              }
            }
          }
          break;
        }
        case "GraphQL Mutation": {
          if (!("schema" in step) || step.schema === null) {
            // Get schema from Schema step (if present)
            if (
              !step.schemas ||
              typeof step.schemas !== "object" ||
              step.schemas === null
            ) {
              step.schemas = {};
            }
            const schemas = this.api.steps
              .filter(
                (step) =>
                  step.type.name === "Schema" &&
                  "schema" in step &&
                  step.schema !== null
              )
              .map((step) => step.schema);
            if (schemas.length > 0) {
              for (const schema of schemas) {
                if (!(schema.key in step.schemas)) {
                  step.schemas[schema.key] = {
                    key: schema.key,
                    name: schema.name,
                    type_name: camelCase(schema.name),
                    plural_name: camelCase(schema.name) + "s",
                    index_name: "id",
                    fields: [],
                  };
                } else {
                  step.schemas[schema.key].fields = Array.isArray(
                    step.schemas[schema.key].fields
                  )
                    ? step.schemas[schema.key].fields
                    : [];
                  step.schemas[schema.key].type_name =
                    !step.schemas[schema.key].type_name ||
                    step.schemas[schema.key].type_name.length === 0
                      ? camelCase(schema.name)
                      : step.schemas[schema.key].type_name;
                  step.schemas[schema.key].plural_name =
                    !step.schemas[schema.key].plural_name ||
                    step.schemas[schema.key].plural_name.length === 0
                      ? camelCase(schema.name) + "s"
                      : step.schemas[schema.key].plural_name;
                  step.schemas[schema.key].index_name =
                    !step.schemas[schema.key].index_name ||
                    step.schemas[schema.key].index_name.length === 0
                      ? "id"
                      : step.schemas[schema.key].index_name;
                }
              }
            }
          }
          break;
        }
        case "Response":
          if (!("status" in step)) {
            step.status = 200;
          }
          if (!("format" in step)) {
            step.format = "json";
          }
          if (!("layout" in step)) {
            step.layout = '{"items":[[items]],"count":[[count]]}';
          }
          if (!("delimiter" in step)) {
            step.delimiter = ",";
          }
          if (!("qualifier" in step)) {
            step.qualifier = '"';
          }
          if (!("use_qualifier" in step)) {
            step.use_qualifier = "always";
          }
          break;
      }
      // console.log("Init After", JSON.stringify(step));
      return step;
    },
    addStep(type) {
      let step = { type: { ...type } };
      step = this.initStep(step);
      const steps = [...this.api.steps, step];
      this.$set(this.api, "steps", steps);
    },
    deleteStep(index) {
      let steps = [...this.api.steps];
      const deletedStep = { ...this.api.steps[index] };
      steps.splice(index, 1);
      this.$set(this.api, "steps", steps);
      if (this.stepIndex === index) {
        this.stepIndex = null;
        this.step = { type: {} };
        this.$forceUpdate();
      }
      if (deletedStep.type.name === "Schema") {
        for (let step of this.api.steps) {
          if (
            step.type.name === "GraphQL Query" ||
            step.type.name === "GraphQL Mutation"
          ) {
            delete step.schemas[deletedStep.schema.key];
          }
        }
      }
    },
    editStep(step, index) {
      // console.log("Step", step, index);
      this.stepIndex = index;
      try {
        this.step = JSON.parse(JSON.stringify(this.initStep(step)));
      } catch (e) {
        this.step = { ...this.initStep(step) };
      }
    },
    saveStep() {
      let step = { ...this.api.steps[this.stepIndex], ...this.step };
      this.$set(this.api.steps, this.stepIndex, step);
      // Replicate schema on Schema step save
      if (
        this.step.type.name === "Schema" &&
        "schema" in this.step &&
        this.step.schema !== null
      ) {
        for (let step of this.api.steps) {
          if (
            step.type.name === "GraphQL Query" ||
            step.type.name === "GraphQL Mutation"
          ) {
            if (!(this.step.schema.key in step.schemas)) {
              step.schemas[this.step.schema.key] = {
                key: this.step.schema.key,
                name: this.step.schema.name,
                fields: [],
              };
            }
          }
        }
      }
      this.notyf.success("Step saved successfully.");
    },
    revertStep() {
      this.step = { ...this.api.steps[this.stepIndex] };
      this.notyf.success("Step reverted successfully.");
    },
    addMapping() {
      const mappings = [...this.step.mappings, {}];
      this.$set(this.step, "mappings", mappings);
      this.$forceUpdate();
    },
    deleteMapping(index) {
      let mappings = [...this.step.mappings];
      mappings.splice(index, 1);
      this.$set(this.step, "mappings", mappings);
    },
    addCondition() {
      const conditions = [...this.step.conditions, {}];
      this.$set(this.step, "conditions", conditions);
      this.$forceUpdate();
    },
    deleteCondition(index) {
      let conditions = [...this.step.conditions];
      conditions.splice(index, 1);
      this.$set(this.step, "conditions", conditions);
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
    getUniqueEndpoint() {
      let endpoint = slugify(this.api.name, { lower: true, strict: true });
      let modifier = 2;
      while (endpoint in this.endpoints) {
        modifier++;
        endpoint = endpoint + "-" + modifier;
      }
      return endpoint;
    },
    addEndpoint(key) {
      const endpoint = this.api.endpoint;
      this.endpoints[endpoint] = key;
      const endpoints = { ...this.endpoints };
      return this.updateObject("endpoints", null, endpoints);
    },
    updateEndpoint() {
      this.api.endpoint = this.getUniqueEndpoint();
    },
    selectProperty(property) {
      this.script += property;
      this.$forceUpdate();
    },
    dataFields(key) {
      // console.log("Data Key", key);
      if (key) {
        if (key in this.data) {
          const data = this.data[key];
          if ("fields" in data && Array.isArray(data.fields)) {
            return data.fields;
          } else {
            return [];
          }
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    toggleSelected(checked, schemaKey) {
      // console.log("Toggle", checked, schemaKey);
      if (checked) {
        this.step.schemas[schemaKey].fields = this.schemas[
          schemaKey
        ].fields.map((field) => {
          return field.name;
        });
      } else {
        this.step.schemas[schemaKey].fields = [];
      }
    },
    commitScript(g) {
      this.githubProcessing = true;
      // console.log(g);
      g.commit(this.api.script)
        .then(() => {
          this.githubProcessing = false;
          this.notyf.success("Project committed successfully.");
        })
        .catch((error) => {
          this.githubProcessing = false;
          console.error(error);
          this.notyf.error(
            "An error occurred while committing. Please try again later."
          );
        });
    },
    copyUrl() {
      navigator.clipboard
        .writeText(this.apiUrl + this.api.endpoint)
        .then(() => {
          setTimeout(() => {
            this.$root.$emit("bv::hide::tooltip");
          }, 1000);
        })
        .catch((error) => {
          console.error("Unable to write to clipboard", error);
        });
    },
  },
};
</script>

<style lang="css" scoped>
.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
  overflow-y: auto;
  height: 100%;
  min-height: 400px;
  max-height: 592px;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
  font-family: "Source Code Pro", serif;
}

.prism-editor-sm {
  height: 250px;
}

.step-list {
  border: 1px solid #545968;
  border-radius: 0.25rem;
}

.list-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}
</style>

<style lang="scss">
.commit-button {
  font-size: 14px;
  font-weight: 600;
  padding: 5.5px 12px;
}
</style>
