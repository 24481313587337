<template>
  <api-editor
    :api="api"
    :projectId="project.objectID"
    @save="update(false)"
    v-on:save-and-exit="update(true)"
    v-if="project.objectID"
  ></api-editor>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import ApiEditor from "@/components/APIEditor";

export default {
  name: "APIEdit",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    ApiEditor,
  },
  data() {
    return {
      project: {},
      refkey: null,
      api: {
        name: "",
        description: "",
        type: "",
        method: "",
        endpoint: "",
        schema: "",
        code: "",
      },
      sources: [],
      root: ["folders", "apis", ""],
      path: "",
      endpoints: {},
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("selected", uid, "project");
      },
    },
    project: {
      immediate: true,
      handler(project) {
        if (project.objectID) {
          this.root[2] = project.objectID;
          console.log("Project", project.objectID, this.$route.query.path);
          if (this.$route.query.path !== undefined) {
            this.setRootFromPath(this.$route.query.path);
            this.path = this.$route.query.path
              .split("/")
              .slice(2, this.$route.query.path.split("/").length)
              .join("/");
          }
          if (this.refkey) {
            this.bindObject("apis/" + project.objectID, this.refkey, "api");
          }
          this.bindObject("endpoints", project.objectID, "endpoints");
        }
      },
    },
    refkey: {
      immediate: true,
      handler(key) {
        if (key && this.project.objectID) {
          this.bindObject("apis/" + this.project.objectID, key, "api");
        }
      },
    },
  },
  methods: {
    async update(exit = false) {
      const api = { ...this.api };
      // Fetch stored api to get current endpoint
      const original = await this.getObjectSnapshot(
        "apis/" + this.project.objectID,
        this.refkey
      );
      // Update stored api
      let promises = [
        this.updateObject("apis/" + this.project.objectID, this.refkey, api),
        this.updateObject(this.root.join("/"), this.refkey, api),
      ];
      // Update endpoint if changed
      if (original.endpoint !== api.endpoint) {
        promises.push(
          this.updateEndpoint(original.endpoint, api.endpoint, this.refkey)
        );
      }
      // Resolve promises and send notifications
      Promise.all(promises)
        .then(() => {
          this.notyf.success("API saved successfully");
          if (exit) {
            this.navigate("apis", { path: this.path });
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error("An error occurred while saving the API");
        });
    },
    updateEndpoint(original, updated, key) {
      this.endpoints[updated] = key;
      let endpoints = { ...this.endpoints };
      delete endpoints[original];
      return this.updateObject("endpoints", this.project.objectID, endpoints);
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["folders", "apis", this.project.objectID];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        const folder = snapshot.val();
        console.log("Folder", folder);
        if ("folders" in folder) {
          Object.keys(folder.folders).forEach((subkey) => {
            const subfolder = folder.folders[subkey];
            if (subfolder.slug === segment.toLowerCase()) {
              root.push("folders", subkey);
            }
          });
        }
      }
      this.root = [...root, "items"];
    },
  },
  created() {
    this.refkey = this.$route.params.id;
  },
};
</script>

<style lang="css" scoped>
.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding-top: 0;
  padding-bottom: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
}
</style>
